
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { IUser } from "@/store/modules/valored/UserModule";
import { ICompany } from "@/store/modules/valored/CompanyModule";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
  name: "kt-account",
  components: {
  },
  setup(){
    const store = useStore();

    const accountInfo =  reactive({ 
      userProfile: computed(() => store.getters.currentUser as IUser )  , 
      companyProfile: computed(() => store.getters.getCompanyProfile as ICompany ),
    });

    const isAdmin = computed(() => store.getters.isAdmin);

    return{
      translate,
      ...toRefs(accountInfo),
      isAdmin
    }
  }
});
